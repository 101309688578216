.mySwiper{
  width: 100%;
  height: 600px;
  overflow: hidden;
}

.swiper-slide{
  background-color: white;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 20px;
}

.bott {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  gap: 20px;
}

.butao{
  padding: 10px;
  color: transparent;
  background-color: transparent;
  border-color: transparent;
  width: 48px;
}



.swiper-wrapper{
  display: flex;
}

