*{
  font-family: 'Poppins', sans-serif;
}

header {
  background-color: #FFFFFF59;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  transition: .3s ease-in-out;
}

header.scrolled {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.top-box {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.top {
  overflow: hidden;
  width: 200%;
  height: 100vh;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  position: absolute;
  top: 0;
  left: -50%; 
}

.top-text-box {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  box-sizing: border-box;
  padding: 0 375px 0 160px;
  text-align: center;
  border-radius: 10px;
}

.fundo1{
  width: 100%;
  height: auto;
  position: absolute;
  z-index: 4;
}


.menu{
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 160px;
  height: 128px;
}

.box-logo {
  display: flex;
  align-self: center;
}

.links{
  display: flex;
  list-style-type: none;
  align-items: center;
  gap: 25px;
}

.links_item {
  display: inline-flex;
  align-self: flex-end;
  font-size: 20px;
}

.links_item a{
  text-decoration: none;
  color: white;
  transition: .3s ease-in-out;
}

header.scrolled .links_item a {
  color: #1E1E1E;
}

.pri2{
  width: 137px;
  height: 30px;
  margin-top: 77px;
  margin-left: 171.17px;
  margin-bottom: 5px;
}

.seg2{
  width: 91px;
  height: 30px;
  margin-top: 77px;
  margin-bottom: 5px;
}

.ter2{
  width: 94px;
  height: 30px;
  margin-top: 77px;
  margin-bottom: 5px;
}

.quar2{
  width: 157px;
  height: 30px;
  margin-top: 77px;
  margin-bottom: 5px;
}

.logo-menu2{
  width: 280.83px;
  height: 48px;
}

header.scrolled .logo-menu2 {
  color: #1E1E1E !important;
}

.bloco{
  display: inline-block;
  width: 100%;
  height: 100px;
  background-color: white;
}

.conteudo1 {
  /* background-image: url('/images/fundo-top.png'); */
  background-image: url('/src/components/img/background-topo.jpg');
  background-size: cover;
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
}

.blocks{
  width: 100%;
  height: auto;
  position: absolute;
  z-index: 10;
  margin: auto;
  bottom: 0;
}

.centro{
  display: flex;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  padding: 0 375px 0 160px;
  text-align: center;
  border-radius: 10px;
}

.texto{
  z-index: 1;
}

.primeiro{
  text-align: left;
  font-size: 64px;
  color: white;
  font-weight: 400;
  text-shadow: 0px 3px 3px #00000040;

}

.terceiro{
  text-align: left;
  color: white;
  font-size: 32px;
  font-weight: 400;
  position: relative;
  line-height: 48px;
  z-index: 2;
  text-shadow: 2px 2px 0px #000000CC;
  /* text-shadow: 1px 1px 2px pink; */
}

.terceiro::before{
  content: '';
  position: absolute;
  display: flex;
  width: 100%;
  height: 55%;
  background: linear-gradient(to right, rgba(115, 236, 19, 0) 0%, rgba(11, 216, 191, 0.75) 100%);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 0 40px 40px 0;
  z-index: -1;
}

.quarto{
  display: flex;
  text-align: left;
  color: white;
  font-size: 32px;
  font-weight: 400;
}

.link3{
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-decoration: none;
  max-width: 229px;
  width: 100%;
  height: 72px;
  background-image: linear-gradient(to right, #73EC13, #0BD8BF);
  border-radius: 8px;
  color: white;
  margin-top: 24px;
}

.box-titulo{
  text-align: center;
  box-sizing: border-box;
  padding: 0 350px;
  margin: 40px 0;
}

.titulo2{
  color: #73EC13;
}

.titulo{
  font-size: 32px;
}

.fotos{
  position: relative;
  display: grid;
  place-items: center;
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
}

.cont{
  position: relative;
  display: flex;
  margin: auto;
}

.caixa{
  width: 80%;
  background-image: linear-gradient(to right, #73EC13, #0BD8BF);
  position: relative;
  z-index: 2;
  display: grid;
  align-items: center;
  place-items: center;
  margin: auto;
  border-radius: 24px;
  margin-top: -100px;
}

.cont2{
  filter: invert(100%);
  opacity: 0.4;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  border-radius: 24px;
}

.lorem{
 z-index: 2;
 margin: auto;
 padding: 48px;
}

.para{
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  color: white;
  margin: auto;
}


.princi{
  font-size: 32px;
  font-weight: 900;
  line-height: 48px;
  color: white;
  margin: auto;
}

.conteudo3{
  width: 100%;
  box-sizing: border-box;
  padding: 56px;
  background-color: #D9F5C3;
  margin-top: 100px;
  text-align: center;
}

.capsule{
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 100vh;
}

.poligon {
    overflow: hidden;
    width: 200%;
    height: 100vh;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    position: absolute;
    top: 0;
    left: -50%;
    
}

.pag{
  font-weight: 600;
  font-size: 22.25px;
  line-height: 48px;
}


.sg{
  color: #5CC00C;
}

.qa{
  color: #5CC00C;
}

.em{
  position: relative;
  display: flex;
  font-size: 24px;
  font-weight: 400;
  line-height: 48px;
  margin-bottom: 20px;
}

.pags{
  display: grid;
  place-items: center;
}

.qg{
  display: grid;
  place-items: center;
  gap: 10px;
}

.cards1{
  display: flex;
  margin: auto;
  gap: 10px;
}

.cards2{
  position: relative;
  display: flex;
  margin: auto;
  gap: 10px;
}

.card1{
  border-radius: 8px;
  width: 363px;
  height: 456x;
}
.card2{
  border-radius: 8px;
  width: 363px;
  height: 456x;
}
.card3{
  border-radius: 8px;
  width: 363px;
  height: 456x;
}
.card4{
  border-radius: 8px;
  width: 363px;
  height: 456x;
}
.card5{
  border-radius: 8px;
  width: 363px;
  height: 456x;
}
.card6{
  border-radius: 8px;
  width: 363px;
  height: 456x;
}

.card{
  position: relative;
  display: grid;
  place-items: center;
}

.conteudo4 {
  box-sizing: border-box;
  padding: 56px 0;
}

.card-item{
  position: absolute;
  margin: auto;
  margin-top: 270px;
  z-index: 2;
  font-size: 16px;
  font-weight: 700;
  line-height: 48px;
  border: 0.1px solid;
  border-color: #73EC13;
  text-decoration: none;
  width: 215px;
  height: 72px;
  padding: 12px, 40px, 12px, 40px;
  background-color: #73EC13;
  border-radius: 8px;
  color: white;
  display: grid;
  text-align: center;
  align-items: center;
}

.repo{
  display: flex;
  place-items: center;
}

.tit{
  position: relative;
  display: grid;
  place-items: center;
  font-size: 22.6px;
  font-weight: 600;
  line-height: 48px;
  margin: auto;
}

.pl{
  color: #5CC00C;
}

.pcb{
  text-align: center;
  margin-top: -20px;
}

.cao{
  position: relative;
  font-size: 17px;
  font-weight: 300;
  line-height: 48px;
  margin: auto
}

.animacao{
  background-color: white;
  padding: 5px;
  overflow: hidden;
}

.scroll{
  width: max-content;
  height: 64px;
  margin-left: -86px;
  overflow: hidden;
  gap: 10px;
  display: flex;
  animation: scrollinfinito 25s linear infinite;
}

.animacao:nth-child(odd) .scroll{
  animation: scrollinfinito2 25s linear infinite;
}

/* .scroll2{
  width: max-content;
  height: 64px;
  margin-left: -86px;
  overflow: hidden;
  gap: 10px;
  display: flex;
  animation: scrollinfinito2 10s linear infinite;
} */

.log{
  background-color: #EDFBF6;
  color: black;
  width: 173px;
  height: 64px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.lo{
 vertical-align: middle;
}

@keyframes scrollinfinito{
  to {
    translate: calc(-50% - 20px);
  }
}



.log2{
  background-color: #EDFBF6;
  color: black;
  width: 173px;
  height: 64px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.lo2{
 vertical-align: middle;
}

@keyframes scrollinfinito2{
  0% {
    transform: translateX(-50%);
}
100% {
    transform: translateX(20px);
}
}

.conteudo5{
  width: 100%;
  padding: 56px 0;
  background-color: #D9F5C3;
  margin-top: 50px;
  text-align: center;
  align-items: center;
  position: static;
}

.titulo1 {
  font-size: 32px;
}

.titi{
  font-size: 32.6px;
  font-weight: 600;
  line-height: 48px;
}

.coc{
  align-items: center;
  font-size: 32px;
}

.verd{
  color: #5CC00C;
}

.baix{
  font-size: 20px;
  font-weight: 300;
  line-height: 48px;
  display: grid;
}

.container5{
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
}

.star{
  display: flex;
  gap: 10px;
}

.mogli{
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  color: black;
  display: flex;
  margin-top: 70px;
}

.boll{
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #D9D9D9;
}

.nameR{
  margin-top: 20px;
  margin-left: 10px;
}

.ffs{
  display: flex;
  margin-top: 50px;
}

.coc2{
  align-items: center;
  margin-top: -10px;
}

.conteiner6{
  width: 742px;
  height: 888px;
  text-align: center;
}

.slider-item {
  /* width: 552px; */
  height: 400px;
}

.logoVer{
  width: 284.65px;
  height: 48px;
  margin: auto;
  display: block;
}

.map{
  display: block;
  margin: auto;
  width: 742px;
  height: 368px;
  margin-top: 50px;
}

.pog{
  display: flex;
  justify-content: center;
  gap: 15px;
  margin: 50px 0;
}

.conteudo6 {
  box-sizing: border-box;
  padding: 56px 10px;
}

.social-icon{
  width: 72px;
  height: 72px;
  background: linear-gradient(to bottom, rgba(115, 236, 19, 0.25) 0%, rgba(255, 255, 255, 0.25) 100%);
  text-align: center;
  display: grid;
  border-radius: 100%;
  margin: auto;
}
.bord2{
  width: 72px;
  height: 72px;
  background-image: linear-gradient(to right, #73EC13, #0BD8BF);
  text-align: center;
  display: grid;
  border-radius: 100%;
  margin: auto;
}
.bord3{
  width: 72px;
  height: 72px;
  background-image: linear-gradient(to right, #73EC13, #0BD8BF);
  text-align: center;
  display: grid;
  border-radius: 100%;
  margin: auto;
}

.fece{
  display: inline-block;
  margin: auto;
  vertical-align: middle;
}

.insta{
  display: inline-block;
  margin: auto;
  vertical-align: middle;
}

.linke{
  display: inline-block;
  margin: auto;
  vertical-align: middle;
}

.pol{
  box-sizing: border-box;
  padding: 0 350px;
}

.pol .titulo {
  font-weight: 600;
}

.pol1{
  margin: auto;
  margin-top: 50px;
}
.pol2{
  margin: auto;
  margin-top: 20px;
}
.pol3{
  margin: auto;
  margin-top: 20px;
}

.did{
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right, #73EC13, #0BD8BF);
  color: white;
  width: 229px;
  height: 72px;
  margin: 0 auto;
  margin-top: 40px;
  border-radius: 8px;
}

.fim{
  width: 100%;
  height: 124px;
  background-color: #D9F5C3;
  display: grid;
  place-items: center;
}

.fim2{
  margin: auto;
  font-size: 15px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
}
.menuHamburger {
  display: none;
  width: 30px;
}

.menuHamburger button, .closeMenu button {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  position: relative
}

.closeMenu {
  display: none;
}

.closeMenu button {
  width: 30px;
  height: 30px;
}

.closeMenu .line {
  position: absolute;
  top: 50%;
}

.closeMenu .line:nth-child(1) {
  transform: rotate(45deg);
}

.closeMenu .line:nth-child(2) {
  transform: rotate(-45deg);
}

.pol .titulo {
  text-align: center;
}

.mogli {
  text-align: left;
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {

  header {
    background-color: #FFF;
  }
  .top-box {
    margin-top: 89px;
  }
  .top-logo {
    fill: #73EC13 !important;
  }
  .logo-menu1 {display: none;}
  .logoMobile {display: flex;}
  .menu {flex-direction: column; width: 300px; height: 100vh; background-color: #FFF; position: absolute; top: 0; right: -300px; padding: 20px; transition: .5s ease-in-out; justify-content: flex-start; gap: 50px;}
  .menu.active {right: 0;}
  .links {flex-direction: column; gap: 15px}
  .links_item {align-self: flex-start;}
  .links_item a {color: #1E1E1E;}
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }

  .menuHamburger {
    display: flex;
    cursor: pointer;
    flex-direction: row;
    gap: 5px;
  }
  
  .closeMenu {
    display: flex;
    cursor: pointer;
  }
  
  .top-text-box {
    height: auto;
    padding: 0 40px;
    display: flex;
    align-self: center;
  }

  .top-box {
    height: 60vh;
    display: flex;
  }

  .top {
    height: 60vh;
  }

  .box-titulo {
    padding: 0 40px;
  }

  .fotos {
    padding: 0 40px;
  }

  .pol {
    padding: 0 40px;
  }
}


@media screen and (max-width: 1023px) {
  header {
    background-color: #FFF;
  }
  .top-box {
    margin-top: 89px;
  }
  .top-logo {
    fill: #73EC13 !important;
  }
  .logo-menu1 {display: none;}
  .logoMobile {display: flex;}
  .menu {flex-direction: column; width: 300px; height: 100vh; background-color: #FFF; position: absolute; top: 0; right: -300px; padding: 20px; transition: .5s ease-in-out; justify-content: flex-start; gap: 50px;}
  .menu.active {right: 0;}
  .links {flex-direction: column; gap: 15px}
  .links_item {align-self: flex-start;}
  .links_item a {color: #1E1E1E;}
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }

  .menuHamburger {
    display: flex;
    cursor: pointer;
    flex-direction: row;
    gap: 5px;
  }
  
  .closeMenu {
    display: flex;
    cursor: pointer;
  }

  .top-text-box {
    padding: 0 20px;
  }

  .box-titulo {
    padding: 0 20px;
  }

  .conteudo3 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .titulo {
    text-align: center;
  }

  .pol {
    padding: 0 20px;
  }

  .caixa {
    width: 90%;
  }

  .services {
    gap: 20px
  }

  .lorem {
    padding: 20px;
  }
  .fim2 {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) {

  video {
    src: url('/public/videos/travelling-md.mp4');
  }

  .top-logo {
    fill: #73EC13 !important;
  }

  .top-box {
    margin-top: 105px;
    height: auto;
  }

  .top-text-box {
    height: 50vh;
    padding-top: 10px;
  }

  .primeiro {
    font-size: 45px;
    line-height: 45px;
  }

  .terceiro {
    font-size: 24px;
    line-height: 24px;
  }

  .link3 {
    font-size: 18px;
    line-height: 18px;
    height: 60px;
    margin-top: 20px;
  }

  .top {
    height: 60vh;
    top: -78px;
  }

  .link3 {
    display: none;
  }
}

@media screen and (max-width: 479px) {
  .top {
    margin-top: -17px;
  }

  .poligon-content {
    margin-top: 78px !important;
  }

  .top-text-box {
    align-items: flex-start;
    padding-top: 40px;
  }
}

